import React, {
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    OverlayTrigger,
    Table,
    Tooltip
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import ShopsContext from "../../context/ShopsContext";
import Loading from "../../components/Loading";
import TagPill from "../../components/pills/TagPill";
import Helmet from "../../components/Helmet";
import NumberFormatter from "../../components/NumberFormatter";

function Courses() {
    const shopsContext = useContext(ShopsContext);
    const [courses, setCourses] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setCourses(null);
        setError(null);
        axios.post("/getCourses", { shopId: shopsContext.currentShop.id })
            .then((response) => {
                if(response.data.valid) {
                    setCourses(response.data.courses);
                } else {
                    setError("Er ging iets fout. Probeer het later opnieuw.");
                }
            })
            .catch(() => {
                setError("Er ging iets fout. Probeer het later opnieuw.");
            });
    }, [shopsContext.currentShop]);

    const baseUrl = "/shop/" + shopsContext.currentShop.codeName;

    return (
        <React.Fragment>
            <Helmet title="Cursussen"/>
            <Title
                preTitle="Overzicht"
                preChildren={
                    <div className="float-right mt-2">
                        <OverlayTrigger overlay={
                            <Tooltip id="newProduct">Nieuwe cursus</Tooltip>
                        }>
                            <Link
                                to={ baseUrl + "/courses/add" }
                                className="btn btn-secondary"
                            >
                                <i className="fas fa-fw fa-plus"/>
                            </Link>
                        </OverlayTrigger>
                    </div>
                }
            >
                Cursussen
                <small>
                    <TagPill className="ml-2">{ courses ? courses.length : 0 }</TagPill>
                </small>
            </Title>
            <Table size="sm" className="results" hover={ courses && courses.length > 0 }>
                <thead className="thead-light">
                <tr className="tr-sticky">
                    <th className="text-center">#</th>
                    <th className="text-left">Naam</th>
                    <th className="text-right">Aantal deelnemers</th>
                    <th className="text-right">Toegangsduur</th>
                </tr>
                </thead>
                <tbody>
                { error ? (
                    <tr>
                        <td colSpan={ 4 }>
                            <Alert variant="danger" className="mb-0">{ this.state.error }</Alert>
                        </td>
                    </tr>
                ) : !courses ? (
                    <tr>
                        <td colSpan={ 4 }>
                            <Loading/>
                        </td>
                    </tr>
                ) : courses.length === 0 ? (
                    <tr>
                        <td colSpan={ 6 } className="text-center">
                            <h1><i className="fas fa-graduation-cap"/></h1>
                            <h4>Geen cursussen</h4>
                            <p>Er staan nog geen cursussen in de shop.</p>
                        </td>
                    </tr>
                ) : courses.map((course) => {
                    const url = baseUrl + "/course/" + course.id;
                    return (
                        <tr key={ course.id } className="table-clickable-row">
                            <th scope="row" className="align-middle text-center">
                                <Link to={ url }>
                                    { course.id }
                                </Link>
                            </th>
                            <td className="align-middle">
                                <Link to={ url }>
                                    { course.title }
                                </Link>
                            </td>
                            <td className="align-middle text-right">
                                <Link to={ url }>
                                    <NumberFormatter number={ course.amountOfUsersWithAccess }/>
                                </Link>
                            </td>
                            <td className="align-middle text-right">
                                <Link to={ url }>
                                    { course.maxAccessDuration === 1 ? (
                                        "1 dag"
                                    ) : (
                                        course.maxAccessDuration + " dagen"
                                    )}
                                </Link>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </React.Fragment>
    );
}

export default React.memo(Courses);
